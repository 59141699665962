import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';

declare module "o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts" {
    export class ServiceWorkerScriptState {
        static objectStoreDexieSchema: string;

        appId: string;
        id: string;
        importmapEntry: IServiceWorkerImportMapEntry;

        constructor(options: {
            appId: string;
            id: string;
            importmapEntry: IServiceWorkerImportMapEntry;
        });

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<ServiceWorkerScriptState | null>;
    }
}
